import TEMPLATEID from '../templates';
import MENUITEMS from './menuItems';

const ROUTEOPTS = {
  routes: {
    '/users/:filter': {
      template: TEMPLATEID.USERSPAGE,
      menuItem: MENUITEMS.USERS,
      typePage: 'page',
      bundles: 'users',
    },
    '/companies': {
      template: TEMPLATEID.COMPANIESPAGE,
      menuItem: MENUITEMS.COMPANIES,
      typePage: 'page',
      bundles: 'companies',
    },
    '/employeesPage': {
      template: TEMPLATEID.EMPLOYEESPAGE,
      menuItem: MENUITEMS.EMPLOYEES,
      typePage: 'page',
      bundles: 'employeesPage',
    },
    '/employeePage/:employeeId': {
      template: TEMPLATEID.EMPLOYEEPAGE,
      typePage: 'page',
      bundles: 'employeePage',
    },
    '/order': {
      template: TEMPLATEID.BOOKTRIPPAGE,
      menuItem: MENUITEMS.BOOKTRIP,
      typePage: 'page',
      bundles: 'bookTrip',
    },
    '/carts-and-notes': {
      template: TEMPLATEID.SEARCHUSERSPAGE,
      menuItem: MENUITEMS.SEARCHUSERS,
      typePage: 'page',
      bundles: 'searchUsers',
    },
    '/search-taxi': {
      template: TEMPLATEID.SEARCH_TAXI,
      menuItem: MENUITEMS.TAXI,
      typePage: 'page',
      bundles: 'searchTaxi',
    },
    '/account/:accountId/company/:companyId/user/:userId': {
      template: TEMPLATEID.CARTSANDNOTESPAGE,
      menuItem: MENUITEMS.SEARCHUSERS,
      typePage: 'page',
      bundles: 'cartsAndNotes',
    },
    '/cart/:accountId/company/:companyId/user/:user/new': {
      template: TEMPLATEID.CART_NEW,
      menuItem: null,
      typePage: 'page',
      bundles: 'cartNew',
    },
    '/cart/:accountId/company/:companyId/user/:user/edit/:cartId': {
      template: TEMPLATEID.CART_EDIT,
      menuItem: null,
      typePage: 'page',
      bundles: 'cartEdit',
    },
    '/cart/:accountId/company/:companyId/user/:user/create-trip/:cartId': {
      template: TEMPLATEID.CART_TO_TRIP,
      menuItem: null,
      typePage: 'page',
      bundles: 'cartToTrip',
    },
    '/railway': {
      template: TEMPLATEID.RAILWAYPAGE,
      menuItem: MENUITEMS.RAILWAY,
      typePage: 'page',
      bundles: 'railway',
    },
    '/airlineCancel': {
      template: TEMPLATEID.AIRLINECANCELPAGE,
      menuItem: MENUITEMS.AIRLINECANCEL,
      typePage: 'page',
      bundles: 'airlineCancel',
    },
    '/vipHallCancel': {
      template: TEMPLATEID.VIP_HALL_CANCEL,
      menuItem: MENUITEMS.VIP_HALL_CANCEL,
      typePage: 'page',
      bundles: 'vipHallCancel',
    },
    '/searchTrips': {
      template: TEMPLATEID.SEARCHTRIPSPAGE,
      menuItem: MENUITEMS.SEARCHTRIPS,
      typePage: 'page',
      bundles: 'searchTrips',
    },
    '/account/:accountId/company/:companyId/trip/:tripId': {
      template: TEMPLATEID.TRIPPAGE,
      menuItem: MENUITEMS.COMPANIES,
      typePage: 'page',
      bundles: 'companies',
    },
    '/account/:accountId/company/:companyId/constructor/:tabId': {
      template: TEMPLATEID.CONSTRUCTORPAGE,
      menuItem: MENUITEMS.COMPANIES,
      typePage: 'page',
      bundles: 'companies',
    },
    '/account/:accountId/company/:companyId/construct/:tabId/new': {
      template: TEMPLATEID.CONSTRUCTOR_NEW,
      menuItem: MENUITEMS.COMPANIES,
      typePage: 'page',
      bundles: 'companies',
    },
    '/account/:accountId/company/:companyId/construct/:tabId/edit/:documentId': {
      template: TEMPLATEID.CONSTRUCTOR_EDIT,
      menuItem: MENUITEMS.COMPANIES,
      typePage: 'page',
      bundles: 'companies',
    },
    '/account/:accountId/company/:companyId/:mainTabId/:tabId': {
      template: TEMPLATEID.COMPANYPAGE,
      menuItem: MENUITEMS.COMPANIES,
      typePage: 'page',
      bundles: 'companies',
    },
    '/settings': {
      template: TEMPLATEID.SETTINGSPAGE,
      menuItem: MENUITEMS.SETTINGS,
      typePage: 'page',
      bundles: 'settings',
    },
    '/bookKeeping/sendingDiadoc': {
      template: TEMPLATEID.SENDINGDIADOCPAGE,
      menuItem: null,
      typePage: 'page',
      bundles: 'sendingDiadoc',
    },
    '/packages/correction': {
      template: TEMPLATEID.PACKAGESCORRECTIONPAGE,
      menuItem: MENUITEMS.PACKAGESCORRECTION,
      typePage: 'page',
      bundles: 'packagesCorrection',
    },
    '/bookKeeping/unloading1c': {
      template: TEMPLATEID.UNLOADING1C,
      typePage: 'page',
      bundles: 'unloading1c',
    },
    '/bookKeeping/payment-hotels': {
      template: TEMPLATEID.PAYMENT_HOTELS,
      typePage: 'page',
      bundles: 'paymentHotels',
    },
    '/bookKeeping/report-taxi': {
      template: TEMPLATEID.REPORT_TAXI,
      typePage: 'page',
      bundles: 'reportTaxi',
    },
    '/report/:area': {
      template: TEMPLATEID.REPORTPAGE,
      menuItem: MENUITEMS.REPORT,
      typePage: 'page',
      bundles: 'report',
    },
    '/diadoc/mailing': {
      template: TEMPLATEID.AUTOSENDINGEDO,
      menuItem: MENUITEMS.AUTOSENDINGEDO,
      typePage: 'page',
      bundles: 'diadocEgo',
    },
    '/report/paymentCard': {
      template: TEMPLATEID.PAYMENT_CARD,
      menuItem: MENUITEMS.PAYMENT_CARD,
      typePage: 'page',
      bundles: 'paymentCard',
    },
    '/report/monetaryTransactions': {
      template: TEMPLATEID.PPR_AND_KONTUR,
      menuItem: MENUITEMS.PPR_AND_KONTUR,
      typePage: 'page',
      bundles: 'monetaryTransactions',
    },
    '/report/generalAct': {
      template: TEMPLATEID.GENERAL_ACT_COMPLETED_WORKS,
      menuItem: MENUITEMS.GENERAL_ACT_COMPLETED_WORKS,
      typePage: 'page',
      bundles: 'generalAct',
    },
    '/amocrm/:amocrmId': {
      template: TEMPLATEID.AMOCRMPAGE,
      menuItem: MENUITEMS.AMOCRM,
      typePage: 'page',
      bundles: 'amoCrm',
    },
    '/amocrm/settingFunnels': {
      template: TEMPLATEID.SETTING_FUNNELS,
      menuItem: MENUITEMS.SETTING_FUNNELS,
      typePage: 'page',
      bundles: 'settingFunnels',
    },
    '/reviseact/:reportId': {
      template: TEMPLATEID.REVISEACT,
      menuItem: null,
      typePage: 'page',
      bundles: 'reviseAct',
    },
    '/login': {
      template: TEMPLATEID.LOGINPAGE,
      typePage: 'app',
      bundles: 'login',
      menuItems: null,
    },
    '/atc/calls': {
      template: TEMPLATEID.CALLSPAGE,
      menuItem: null,
      typePage: 'page',
      bundles: 'calls',
    },
    '/hotels/:hotelsPageId/:hotelId': {
      template: TEMPLATEID.HOTELS_PAGE,
      menuItem: MENUITEMS.HOTELS,
      typePage: 'page',
      bundles: 'hotels',
    },
    '/regions/:regionsPageId/:regionId': {
      template: TEMPLATEID.REGIONS_PAGE,
      menuItem: MENUITEMS.REGIONS,
      typePage: 'page',
      bundles: 'regions',
    },
    '/autoSendingEmail': {
      template: TEMPLATEID.AUTO_SENDING_EMAILS,
      menuItem: MENUITEMS.AUTO_SENDING_EMAILS,
      typePage: 'page',
      bundles: 'autoSendingEmails',
    },
    '/calculator': {
      template: TEMPLATEID.CALCULATOR,
      menuItem: MENUITEMS.CALCULATOR,
      typePage: 'page',
      bundles: 'calculator',
    },
    '/mice': {
      template: TEMPLATEID.MICE,
      menuItem: MENUITEMS.MICE,
      typePage: 'page',
      bundles: 'mice',
    },
    '/clientSession': {
      template: TEMPLATEID.CLIENT_SESSION,
      menuItem: MENUITEMS.CLIENT_SESSION,
      typePage: 'page',
      bundles: 'clientSession',
    },
    '/vacation': {
      template: TEMPLATEID.VACATION,
      menuItem: MENUITEMS.VACATION,
      typePage: 'page',
      bundles: 'vacation',
    },
    '/vac/:accountId/company/:companyId/trip/:tripId': {
      template: TEMPLATEID.VACATION_TRIP,
      menuItem: MENUITEMS.VACATION,
      typePage: 'page',
      bundles: 'vacationTrip',
    },
    '/vac/:accountId/company/:companyId/:mainTabId/:tabId': {
      template: TEMPLATEID.VACATION_ACCOUNT,
      menuItem: MENUITEMS.VACATION,
      typePage: 'page',
      bundles: 'vacationAccount',
    },
  },
};

export default ROUTEOPTS;
