const COMPANY = {
  INIT: 'init',
  SETID: 'setId',

  LOADPROFILE: 'profile.loadProfile',
  LOAD_ACCOUNT_TYPES: 'profile.loadAccountTypes',
  LOADEMPLOYEES: 'employees.loadEmployees',
  LOADFINANCE: 'finance.loadFinance',
  DELETE_TRANSACTION: 'finance.deleteTransaction',
  DELETE_PLANFEE_TRANSACTION: 'finance.deletePlanFeeTransaction',
  LOADING_RESPONSE_FOR_APPROVE: 'finance.loadingResponseForApprove',
  LOADACCOUNTING: 'accounting.loadAccounting',
  LOAD_FIO_SCHEMAS_CLOSING: 'accounting.loadFioSchemasClosing',
  EDIT_FIO_SCHEMAS_CLOSING: 'accounting.editFioSchemasClosing',
  DISABLED_FIO_SCHEMAS_CLOSING: 'accounting.disabledFioSchemasClosing',
  LOADDOCUMENTS: 'documents.loadDocuments',
  LOADPACKAGEDETAILS: 'documents.loadPackageDetails',
  LOADPACKAGECHANGES: 'documents.loadPackageChanges',
  LOADPACKAGEDOCUMENTS: 'documents.loadPackageDocumentsCorrection',
  CHANGEDATE: 'documents.changeDate',
  LOADTRIPS: 'trips.loadTrips',
  LOADLIMITAMOUNT: 'accounting.loadLimitAmount',
  LOAD_REQUISITES: 'profile.loadRequisites',
  LOADING_USER_COMPANIES: 'profile.user.companies',
  LOADING_USER_SETTINGS: 'profile.userSettings',
  LOADING_COMPANY_SETTINGS: 'profile.companySettings',
  SET_COMMENT_COMPANY: 'documents.setcommentcompany',
  GET_COMMENT_COMPANY: 'documents.getcommentcompany',
  SET_SHEMAS_TAX_SA: 'documents.setTaxShemasSA',
  GET_SHEMAS_TAX_SA: 'documents.getTaxShemasSA',
  LOAD_COMPANY_SETTINGS: 'documents.loadCompanySettings',
  SET_LOADING_COMPANY_SETTINGS: 'documents.setLoadingCompanySettings',

  SET_PERSONAL_TRANSACTIONS: 'paidByCard.getPersonalTransactions',
  SET_COMPANY_USERS: 'paidByCard.getCompanyUsers',
  LOADING_PERSONAL_TRANSACTIONS: 'paidByCard.setLoading',
  LOADING_COMPANY_USERS: 'paidByCard.setLoadingUsers',
  LOADING_SENDING_RECEIPT: 'paidByCard.setLoadingSendingReceipt',

  UPDATEDETAILS: 'profile.updateDetails',
  UPDATEAVIACONTRACTS: 'profile.updateAviaContracts',
  UPDATEAVIACONTRACTSDELETE: 'profile.updateAviaContractSDelete',
  UPDATEOVERDRAFT: 'profile.updateOverdraft',
  UPDATEUSERS: 'profile.updateUsers',
  UPDATEA1CFF: 'profile.update.application.flag',
  UPDATEFINANCEPAGINATION: 'finance.updatePagination',
  UPDATE_FINANCE_PAGINATION_ONLY_PLAN_FEE: 'finance.updatePaginationOnlyPlanFee',
  UPDATE_FINANCE_PAGINATION_ONLY_BANK_PAYMENT: 'finance.updatePaginationOnlyBankPayment',
  UPDATE_FINANCE_PAGINATION_ONLY_CARD_PAYMENT_OR_TRANSACTION_FEE: 'finance.updatePaginationOnlyCardPaymentOrTransactionFee',
  RESET_FILTERS: 'finance.resetFilters',
  UPDATEFINANCEVALIDATION: 'finance.validation',
  UPDATEFINANCECOMPONENT: 'finance.component',
  UPDATEACCOUNTING: 'accounting.updateAccounting',
  UPDATEPERIODUPD: 'accounting.updatePeriodUpd',
  UPDATEPERIODEDO: 'accounting.updatePeriodEdo',
  UPDATESENDINGTYPE: 'accounting.updateSendingType',
  UPDATESCHEMAUPD: 'accounting.updateSchemaUpd',
  UPDATELOWBALANCE: 'accounting.updateLowBalance',
  UPDATESELECTEDPACKAGES: 'documents.updateSelectedPackages',
  FORMEDREVISEFLAG: 'documents.formedRevise',
  UPDATEDOCUMENTSPAGINATION: 'documents.updatePagination',
  UPDATETRIPS: 'trips.updateTrips',
  UPDATEUSERCOMMENT: 'profile.updateUserComment',
  UPDATE_REQUISITES: 'profile.update.requisites',
  UPLOAD_FILE: 'profile.upload.file',
  SEND_UPLOAD_EMPLOYEES_FILE: 'profile.send.upload.employees.file',
  SEND_BULK_UPLOAD_FILE: 'profile.send.bulk.upload.file',
  SECOND_PHASE_LOADING: 'profile.second.phase.loading',
  UPLOAD_RIGHTS_EMPLOYEE: 'profile.upload.file.rigths',

  RESETFINANCE: 'finance.reset',
  RESETFINANCEVALIDATION: 'finance.resetValidation',
  RESETDEBITORCREDITDIALOGSTATE: 'finance.resetDebitOrCreditDialogState',
  RESETDOCUMENTS: 'documents.reset',

  LOADINTEGRATION: 'loadIntegration',

  DEBTSSTARTLOADING: 'debts.startLoading',
  DEBTSLOAD: 'debts.load',
  DEBTSERROR: 'debts.error',
  DEBTSRESET: 'debts.reset',

  GET_COMPANY_DEBT: 'accounting.getCompanyDebt',

  EXPENSE_REPORTS_LOADING: 'expenseReports.startLoading',
  EXPENSE_REPORTS_UPDATE: 'expenseReports.update',
  EXPENSE_REPORTS_ERROR: 'expenseReports.error',
  EXPENSE_REPORTS_RESET: 'expenseReports.reset',
  EXPENSE_REPORTS_DELETE_ITEM_LOADING: 'expenseReports.deleteItemLoading',

  LOADINSURENCE: 'loadInsurance',
  SAVEINSURENCE: 'saveInsurance',

  CLEARLIMITAMOUNT: 'clear.limitAmount',
  CLEAR_USER_SETTINGS: 'clear.userSettings',

  SEND_DIADOC: 'SEND_DIADOC',

  ERROR_EXPENSE_REPORT: 'errorExpenseReport',

  UPDATE_HOTEL_CONTRACT_DATA: 'updateHotelContractData',
  SHOW_HOTEL_CONTRACT: 'showHotelContract',
  ADD_HOTEL_CONTRACT: 'addHotelContract',
  GET_RATES_AANDA: 'getRatesAanda',
  DELETE_RATES_AANDA: 'deleteRatesAanda',
  SHOW_HOTEL_CONTRACT_FAIL: 'showHotelContractFail',
  CLOSE_HOTEL_CONTRACT: 'closeHotelContract',
  SAVE_CONTRACT: 'saveContract',
  COPY_CONTRACT: 'copyContract',
  SAVE_CONTRACT_FAIL: 'saveContractFail',
  DELETE_CONTRACT: 'deleteContract',
  UPLOAD_HOTEL_CONTRACT: 'uploadHotelContract',
  UPLOAD_HOTEL_CONTRACT_FAIL: 'uploadHotelContractFail',
  GET_ROOM_TYPE: 'getRoomType',
  ADD_PERIOD_RATE: 'addPeriodRate',
  DELETE_PERIOD_RATE: 'deletePerioRate',
  CHANGE_PERIOD_RATE: 'changePeriodRate',
  LOADING_SAVE: 'loadingSave',
  ADD_RATE: 'addRate',
  COPY_RATE: 'copyRate',
  UPDATE_RATES: 'updateRate',
  REMOVE_RATES: 'removeRate',
  DELETE_RATE: 'deleteRate',
  CLOSE_ERROR: 'closeError',
  CHECK_BOOKING: 'checkBooking',
  CHECK_BOOKING_FAIL: 'checkBookingFail',
  UPLOAD_FILE_FAIL: 'profile.upload.file.fail',
  UPDATE_EMPLOYEE_DOCUMENT_LOADING: 'profile.update.file.loading',
  CLEAR_UPLOADED_FILE: 'profile.upload.clear.file',

  SHOW_EDIT_USER_DIALOG: 'showEditUserDialog',
  SHOW_USER_RIGHTS_DIALOG: 'showUserRightsDialog',
  SHOW_NEXT_STEP_DISABLE_OF_RIGHTS_DIALOG: 'showNextStepDisableOfRightsDialog',
  USER_SET_RIGHTS: 'userSetRights',
  GET_USER_APPROVE_SCHEME: 'getUserApproveScheme',
  GET_SETTING_DISPLAY_DATA: 'getSettingsDisplayData',
  GET_CARD_PAYMENT_FEE: 'getCardPaymentFee',
  SAVE_ERROR_SET_RIGHTS: 'saveErrorSetRightUser',
  SHOW_CANCEL_OF_RIGHTS_DIALOG: 'showCancelOfRightsDialog',
  USER_RIGHT_BUTTONS: 'userRightButton',
  UPDATE_USER_DATA: 'updateUserData',
  SAVE_USER_DATA: 'saveUserData',
  SAVE_USER_DATA_FAIL: 'saveUserData',
  SEND_USER_DOC: 'sendUserDoc',
  WAITING_SEND_USER_DOC: 'waitingSendUserDoc',
  SEND_USER_DOC_FAIL: 'sendUserDocFail',
  LOADING_USERS: 'loading',
  LOADING_COMPANY: 'loadingCompany',

  ADD_AVIA_CONTRACT: 'addAviaContract',
  UPDATE_AVIA_CONTRACT_DATA: 'updateAviaContractData',
  SHOW_AVIA_CONTRACT: 'showAviaContract',
  CLOSE_AVIA_CONTRACT: 'closeAviaContract',
  DELETE_AVIA_CONTRACT_FAIL: 'deleteAviaContractFail',
  SAVE_AVIA_CONTRACT_FAIL: 'saveAviaContractFail',
  CLOSE_ERROR_AVIA_CONTRACT: 'closeErrorAviaContract',
  LOADING_SAVE_AVIA: 'loadingSaveAvia',

  UPDATE_DELETE_DIALOG: 'updateDeleteDialog',

  UPDATE_STATISTIC_FUNCTIONAL: 'updateStatisticFunctional',
  UPDATE_STATISTIC_COMPLAINTS: 'updateStatisticComplaint',
  UPDATE_LOADING_COMPLAINTS: 'updateLoadingComplaint',
  CREATE_STATISTIC_COMPLAINT: 'createStatisticComplaint',
  REMOVE_STATISTIC_COMPLAINT: 'removeStatisticComplaint',
  UPDATE_STATISTIC_BONUSES: 'updateStatisticBonuses',
};

export default COMPANY;
