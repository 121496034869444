const AMOCRM = {
  PROCESSAMOREPORT: 'processAmoReport',
  UPLOADCONTACTS: 'uploadContacts',
  UPLOADCONTACTSFAIL: 'uploadContactsFail',
  RESETERROR: 'resetError',
  RESET: 'reset',
  UPLOADFILE: 'uploadFile',
  UPLOADFILEFAIL: 'uploadFileFail',
  RESETSTORE: 'resetStore',
  PROCESSING_UPDATE_CONTACTS: 'processingUpdateContacts',
  UPDATE_CONTACTS: 'updateContacts',
  NO_UPDATE_CONTACTS: 'noUpdateContacts',
  LOADING_CONTACTS: 'loadingContacts',
  DOWNLOAD_CONTACTS: 'downloadAmoContacts',
  NO_DOWNLOAD_CONTACTS: 'downloadAmoContacts',
  SETTING_FUNNELS: 'settingFunnels',
  TARGET_FUNNEL: 'targetFunnel',
  DUPLICATE_FUNNEL: 'duplicateFunnel',
};

export default AMOCRM;
